import React from "react"
import { graphql } from "gatsby"
import Typist from "react-typist"
import {
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  FormControl,
  Button,
} from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import pmLogo from "../images/penmate-logo-text.png"
import googleAppStoreBadge from "../images/google-app-store-badge.png"
import appleAppStoreBadge from "../images/apple-app-store-badge.png"
import recentPostcard1 from "../images/recent-postcard-1.jpeg"
import recentPostcard2 from "../images/recent-postcard-2.jpeg"
import recentPostcard3 from "../images/recent-postcard-3.jpeg"
import recentPostcard4 from "../images/recent-postcard-4.jpeg"

import customerPic1 from "../images/CustomerPic1.png"
import customerPic2 from "../images/CustomerPic2.png"
import customerPic3 from "../images/CustomerPic3.png"
import customerPic4 from "../images/CustomerPic4.png"

import EventLogger from "../lib/amplitude"
import stateNames from "../lib/stateNames"
import "./landingPage.scss"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { FaQuoteLeft } from 'react-icons/fa';


export default ({ data }) => {
  const [formValue, setFormValue] = React.useState({})

  const formValid =
    !!formValue.firstName && !!formValue.lastName

  const onFirstNameChange = e => {
    setFormValue({ ...formValue, firstName: e.target.value })
  }

  const onLastNameChange = e => {
    setFormValue({ ...formValue, lastName: e.target.value })
  }

  const onStateChange = e => {
    setFormValue({ ...formValue, state: e.target.value })
  }

  const onSubmitSearch = () => {
    const { firstName, lastName, state } = formValue
    EventLogger.logEvent("Landing Page Search", { firstName, lastName, state })
    let url =  `https://m.penmateapp.com/search?first_name=${firstName}&last_name=${lastName}`;
    if (!!state) {
      url = `${url}&state=${state}`
    }
    window.location = url;
  }

  const onOpenAppStore = (device) => {
    let url;
    if (device === 'ios') {
      url = "https://itunes.apple.com/app/id955317881"
    } else {
      url = "https://play.google.com/store/apps/details?id=com.penmate"
    }
    EventLogger.logEvent("Landing Page: Goto App Store", { device, url })
    window.location = url;
  }

  const page = data.page
  return (
    <Layout>
      <SEO
        title={page.title}
        description={page.metaDescription}
        meta={[
          {
            property: `keywords`,
            content: page.metaKeywords,
          },
        ]}
      />

      <div className="main-hero">
        <Container>
          <div className="pm-logo-text"></div>
          <div className="hero-h1">
            <h1 dangerouslySetInnerHTML={{ __html: page.hero_h1 }}></h1>
            <Form>
              <h3>Need help finding someone? <br/> <span className="strong">Try it out free:</span></h3>
              <div className="search-form">
                <div className="search-input">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="input-firstname">
                        First name
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="First name"
                      aria-describedby="input-firstname"
                      onChange={onFirstNameChange}
                    />
                  </InputGroup>
                </div>
                <div className="search-input">
                  <div className="search-input">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="input-lastname">
                          Last name
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        aria-label="Last name"
                        aria-describedby="input-lastname"
                        onChange={onLastNameChange}
                      />
                    </InputGroup>
                  </div>
                </div>
                <Button
                  className="submit-button"
                  block
                  size="lg"
                  onClick={onSubmitSearch}
                  disabled={!formValid}
                >
                  Search
                </Button>
              </div>
            </Form>
          </div>
        </Container>
      </div>

      <Container fluid className="middle-container">
        <Row>
          <Col sm={12}>
            <div className="hero-h2">
              {/*<h2
                className="header-title header-h2"
                dangerouslySetInnerHTML={{ __html: page.hero_h2 }}
              ></h2>*/}
              <h2>Penmate helps you connect with loved ones in prison ♥️</h2>
              <h4>Find an inmate and <strong>e-mail</strong> letters, photos, and more. </h4>
              <h4 className="cta">Get replies direct to your inbox.</h4>
              <div className="h-divider"></div>

              <div className="device-screenshot"></div>

              <div className="feature-list">
                <h2>Everything you need to stay connected</h2>
                <Row>
                  <Col lg={4}>
                    <div className="feature feature-1">
                      <div class="gesture-wrapper">
                        <div className="gesture gesture-step1"></div>
                      </div>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: page.feature1_title,
                        }}
                      ></h3>
                      <p
                        className="subtitle"
                        dangerouslySetInnerHTML={{
                          __html: page.feature1_text,
                        }}
                      ></p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="feature feature-2">
                      <div class="gesture-wrapper">
                        <div className="gesture gesture-step2"></div>
                      </div>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: page.feature2_title,
                        }}
                      ></h3>
                      <p
                        className="subtitle"
                        dangerouslySetInnerHTML={{
                          __html: page.feature2_text,
                        }}
                      ></p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="feature feature-3">
                      <div class="gesture-wrapper">
                        <div className="gesture gesture-step3"></div>
                      </div>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: page.feature3_title,
                        }}
                      ></h3>
                      <p
                        className="subtitle"
                        dangerouslySetInnerHTML={{
                          __html: page.feature3_text,
                        }}
                      ></p>
                    </div>
                  </Col>
                </Row>
              </div>
              
              {/*<p
                className="subheader-text"
                dangerouslySetInnerHTML={{ __html: page.body_p1 }}
              ></p>*/}
            </div>

            <div className="h-divider"></div>
          
            <div id="recent-postcards">
              <h3>Join others and send something special...</h3>
              <div className="postcards">
                <div className="postcard">
                   <img src={recentPostcard1} alt="Example postcard" />
                   <div class="customer">
                      <div class="customer-pic">
                        <img src={customerPic1} alt="A picture from Penmate Customer" />
                      </div>
                      <p> Sent by <span>Rosalia H.</span></p>
                   </div>
                </div>
                <div className="postcard">
                   <img src={recentPostcard2} alt="Example postcard" />
                   <div class="customer">
                      <div class="customer-pic">
                        <img src={customerPic2} alt="A picture from Penmate Customer" />
                      </div>
                      <p> Sent by <span>Darius L.</span></p>
                   </div>
                </div>
                <div className="postcard">
                   <img src={recentPostcard3} alt="Example postcard" />
                   <div class="customer">
                      <div class="customer-pic">
                        <img src={customerPic3} alt="A picture from Penmate Customer" />
                      </div>
                      <p> Sent by <span>Fabi G.</span></p>
                   </div>
                </div>
                <div className="postcard">
                   <img src={recentPostcard4} alt="Example postcard" />
                   <div class="customer">
                      <div class="customer-pic">
                        <img src={customerPic4} alt="A picture from Penmate Customer" />
                      </div>
                      <p> Sent by <span>Tenesha W.</span></p>
                   </div>
                </div>
              </div>
            </div>

          </Col>

        </Row>
      </Container>
      <section id="about-us" className="ss-style-doublediagonal">
        <Container className="about-us-content">
            <h3>Created to help people like us</h3>
            <div className="h-divider"></div>
            <p> 
              <strong>We created Penmate to help families like ours.</strong> We have loved ones serving time and we 
              know how difficult it is to stay in touch. Mailing letters and photos takes time, but to anyone
              in prison it means the world when they hear from us.</p>

              <p>Our team created Penmate to help make it easy to find
              and communicate with anyone in prison. We use it to send love and support to the people we care about.  We hope you will too.
             </p>
        </Container>
          
      </section>


      <section id="try-it-out" className="ss-style-doublediagonal-reverse">
        <Container className="try-it-out-content">
         <h3 className="try-it-free header-title">
          Get started now
          </h3>
          <Form>
            <div className="search-form">
              <div className="search-input">
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="input-firstname">
                      First name
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label="First name"
                    aria-describedby="input-firstname"
                    onChange={onFirstNameChange}
                  />
                </InputGroup>
              </div>
              <div className="search-input">
                <div className="search-input">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="input-lastname">
                        Last name{" "}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="Last name"
                      aria-describedby="input-lastname"
                      onChange={onLastNameChange}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="search-input select-state">
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="input-lastname">
                      State/Fed
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control as="select" onChange={onStateChange}>
                    <option value="">Choose state or federal</option>
                    <option value="Federal">Federal</option>
                    <option value="NY">NY</option>
                    <option value="MN">MN</option>
                    <option value="TX">TX</option>
                    <option value="AZ">AZ</option>
                    <option value="NJ">NJ</option>
                    <option value="AL">AL</option>
                    <option value="CA">CA</option>
                    <option value="PA">PA</option>
                    <option value="VA">VA</option>
                    <option value="GA">GA</option>
                    <option value="KY">KY</option>
                    <option value="WA">WA</option>
                    <option value="WI">WI</option>
                    <option value="IN">IN</option>
                    <option value="MI">MI</option>
                    <option value="NC">NC</option>
                    <option value="OH">OH</option>
                    <option value="IL">IL</option>
                    <option value="FL">FL</option>
                    <option value="MO">MO</option>
                  </Form.Control>
                </InputGroup>
              </div>
              <Button
                className="submit-button"
                block
                size="lg"
                onClick={onSubmitSearch}
                disabled={!formValid}
              >
                Search
              </Button>
            </div>
          </Form>



          <Row>

            <div className="copyright">
              <p class="try-it-free">or download the app and try it free</p>
              <Row>
                <Col xs={6}>
                  <a onClick={() => onOpenAppStore('ios') } className="link-button">
                    <img
                      src={appleAppStoreBadge}
                      className="apple app-store-badge"
                      alt="Download Penmate from the App Store"
                    />
                  </a>
                </Col>
                <Col xs={6}>
                  <a onClick={() => onOpenAppStore('android') }>
                    <img
                      src={googleAppStoreBadge}
                      className="google app-store-badge"
                      alt="Download Penmate from the Play Store"
                    />
                  </a>
                </Col>
              </Row>
              <p className="made-with-love"> Made with love by</p>

              <a href="https://penmateapp.com" className="link-button">
                <img
                  src={pmLogo}
                  alt="Penmate: Find an inmate and write from your phone"
                  className="pm-logo-bottom"
                />
              </a>
              <p>© Penmate 2020</p>
            </div>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    page(slug: { eq: $slug }) {
      title
      slug
      metaDescription
      metaKeywords
      hero_h1
      hero_h2
      body_p1
      feature1_title
      feature1_text
      feature2_title
      feature2_text
      feature3_title
      feature3_text
    }
  }
`
